/*
Version 0.115

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	01-03-2018
	- [ADDED] Added document.ready to onready.
	26-02-2018
	- [ADDED] Open en Close methods are extended with new function to prevent body scrolling when menu is open
2017
	30-11-2017
	- [CHANGE] .js-menu-button is now changed to .js-mobile-menu-button
	15-10-2017
	- [METHOD] Add and remove .active class to the js-menu-button.
	30-08-2017
	- [BORN] This class is born. Hooray!

!Usage
	specific_mobilemenu.onready();

	
!Wishlist

	none
	
*/
  
var specific_slideshows = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	this.onready = function(
		orientation,
		resolution
	) {

		if(orientation == 0) {
			return false;
		}

		/*
		Landscape or portrait
		*/
		if(orientation == 'landscape') {
			
			/*
			Smartphone
			*/
			if(_device == 'smartphone') {
				_url 				=  globalUrl + '/a/l/slideshows-smartphone-landscape';
			} else {
				_url 				=  globalUrl + '/a/l/slideshows-landscape';
			}

			_loadedLandscape 		= 1;

		} else {

			/*
			Smartphone
			*/
			if(_device == 'smartphone') {
				_url 				=  globalUrl + '/a/l/slideshows-smartphone-portrait';
			} else {
				_url 				=  globalUrl + '/a/l/slideshows-portrait';
			}
			
			_loadedPortrait 		= 1;

		}

		/*
		Loading: Slidehows
		*/
		$.ajax({
			url: _url,
			headers: { 
				'go-load':1 
			}
		})
		.done(function(data) {

			/*
			Orientation *********************
			Orientation *********************
			Orientation *********************
			Orientation *********************
			*/

			if(isOrientation == 'landscape') {

				/*
				Smartphone
				*/
				if(_device == 'smartphone') {

					/*
					Show
					*/
					$('.js-landscape-slideshows-loading').html(data);

					/*
					Set: Resolution
					*/
					specific_slideshows_smartphone.setResolution(
						resolution
					);

					/*
					Init: Swiper
					*/
					specific_slideshows_smartphone.initSwiper();

				} else {

					/*
					Show
					*/
					$('.js-landscape-slideshows-loading').html(data);

					/*
					Set: Resolution
					*/
					specific_slideshows.setResolution(
						resolution
					);

					/*
					Init: Swiper
					*/
					specific_slideshows.initSwiper();

					/*
					Activate mousecursor check
					*/
					specific_slideshows.mouseTracking();

				}

			} else {

				/*
				Smartphone
				*/
				if(_device == 'smartphone') {
									
					$('.js-portrait-slideshows-loading').html(data);

					/*
					Set: Resolution
					*/
					specific_slideshows_smartphone.setResolution(
						resolution
					);

					/*
					Init: Swiper
					*/
					specific_slideshows_smartphone.initSwiper();

				} else {

					$('.js-portrait-slideshows-loading').html(data);

					/*
					Set: Resolution
					*/
					specific_slideshows.setResolution(
						resolution
					);

					/*
					Init: Swiper
					*/
					specific_slideshows.initSwiper();

					/*
					Activate mousecursor check
					*/
					specific_slideshows.mouseTracking();

				}

			}

		})
		.always(function() {
		});

	};
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	/*
	Set: Resolution
	*/
	this.setResolution = function(
		resolution
	) {

		/*
		Bigger than 1920
		*/
		if(resolution == '1920+') {
			_aspectRatio	= 1920;
			_newHeight 		= 842;

			$('.js-slideshow').height(_newHeight);

			$('.js-portrait-slideshows-loading').hide();
			$('.js-landscape-slideshows-loading').show();
		} 

		/*
		Bigger than 1920 and lower
		*/
		if(resolution == '1920-') {
			_aspectRatio	= 1920 / 1080;
			_newHeight 		= Math.round($(window).width() / _aspectRatio) / 100 * 78;

			$('.js-slideshow').height(_newHeight);

			$('.js-portrait-slideshows-loading').hide();
			$('.js-landscape-slideshows-loading').show();
		}

		/*
		Portrait
		*/
		if(resolution == 'portrait') {

			_aspectRatio	= 1080 / 1920;
			_newHeight 		= Math.round($(window).width() / _aspectRatio);

			$('.js-slideshow').height(_newHeight);

			$('.js-landscape-slideshows-loading').hide();
			$('.js-portrait-slideshows-loading').show();
		}

	};

	/*
	Swiper
	Swiper
	Swiper
	Swiper
	*/
	this.initSwiper = function() {

		/*
		Slideshows
		*/
		$('.swiper-container').each(function(index, element) {

			var $this = $(this);
			$this.addClass("instance-" + index);

			$this.parent().find('.js-slideshow-left').addClass("btn-prev-" + index);
    		$this.parent().find('.js-slideshow-right').addClass("btn-next-" + index);

			var swiper = new Swiper('.instance-' + index, {

			navigation: {
			    nextEl: '.btn-next-' + index,
			    prevEl: '.btn-prev-' + index
			},

			on: {
			      
			      init: function () {

			      	/*
			      	Check
			      	*/

			      	/*
			      	Start first video
			      	*/
				    $('.swiper-slide-active').find('video').trigger('play');

			      },

			      slideChange: function() {

			      	/*
			      	Start / stop video
			      	*/
			      	setTimeout(function() {
				      	$('.swiper-slide-active').find('video').trigger('play');
				      	$('.swiper-slide-prev').find('video').trigger('pause');
				      	$('.swiper-slide-next').find('video').trigger('pause');
			      	},100);

			      	/*
			      	Set: Navigation
			      	*/
					if(this.activeIndex == (this.slides.length-1)) {

						this.$el.parent().find('.js-slideshow-left').addClass('active');
						this.$el.parent().find('.js-slideshow-right').removeClass('active');

					} else if(this.activeIndex == 0) {

						this.$el.parent().find('.js-slideshow-right').addClass('active');
						this.$el.parent().find('.js-slideshow-left').removeClass('active');

					} else {

						this.$el.parent().find('.js-slideshow-left').addClass('active');
						this.$el.parent().find('.js-slideshow-right').addClass('active');

					}

			      }
			      
			    }

			});

		});

	};

	/*
	Mousetracking
	Mousetracking
	Mousetracking
	Mousetracking
	*/
	this.mouseTracking = function() {
		
		$(document).mousemove(function(e) {  

	        _mX = e.pageX;
	        _mY = e.pageY;

	        /*
		 	Next and Prev
		 	*/
	        $('.js-slideshow-left').each(function() {

	        	var _id = $(this).data('id');

		        _distance = specific_slideshows.calculateDistance('.js-slideshow-left-' + _id, _mX, _mY);
		       
		         /*
		        Interact
		        */
		       	if(_distance < 400) {
		       		
		       		if(
		       			$('.js-slideshow-left-' + _id).hasClass('active')
		       		) {
		        		$('.js-slideshow-left-' + _id).addClass('zoom');
		        	}

		        } else {

		        	$('.js-slideshow-left-' + _id).removeClass('zoom');
		        	
				}

		    });

		 	$('.js-slideshow-right').each(function() {

	        	var _id = $(this).data('id');

		        _distance = specific_slideshows.calculateDistance('.js-slideshow-right-' + _id, _mX, _mY);
		       
		        /*
		        Interact
		        */
		       	if(_distance < 400) {
		       		
		       		if(
		       			$('.js-slideshow-right-' + _id).hasClass('active')
		       		) {
		        		$('.js-slideshow-right-' + _id).addClass('zoom');
		        	}

		        } else {
	
		        	$('.js-slideshow-right-' + _id).removeClass('zoom');

				}

		    });

		    $('.js-down-icon').each(function() {

	        	var _id = $(this).data('id');

		        _distance = specific_slideshows.calculateDistance('.js-down-icon', _mX, _mY);
		       
		        /*
		        Interact
		        */
		       	if(_distance < 400) {
		       		
		       		$('.js-down-icon').addClass('zoom');

		        } else {
	
		        	$('.js-down-icon').removeClass('zoom');

				}

		    });

		 	/*
		 	Icon
		 	*/
		    $('.js-slideshow-icon').each(function() {

	        	var _id = $(this).data('id');

		        _distance = specific_slideshows.calculateDistance('.js-slideshow-icon-' + _id, _mX, _mY);
		       
		        /*
		        Interact
		        */
		       	if(_distance < 400) {
		       		
		        	$('.js-slideshow-icon-' + _id).addClass('zoom');

		        } else {
	
		        	$('.js-slideshow-icon-' + _id).removeClass('zoom');

				}

		    });


	    });

	};

	this.calculateDistance = function(elem, mouseX, mouseY) {

		elem = $(elem);

        return Math.floor(Math.sqrt(Math.pow(mouseX - (elem.offset().left+(elem.width()/2)), 2) + Math.pow(mouseY - (elem.offset().top+(elem.height()/2)), 2)));
    };

		
}).apply(specific_slideshows); 