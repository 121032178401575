/*
Version 0.115

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2021
	20-01-2021
	- [BORN] This class is born. Hooray!

!Usage
	specific_mobilemenu.onready();

	
!Wishlist

	none
	
*/
  
var specific_spotlightvideo = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	// none
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************x
	// METHODS ******************************************************************
	
	this.onready = function() {

		$('.js-spotlightvideo').on('click',function() {
			if(_videoPlay == 0) {
				specific_spotlightvideo.play();
			}
		});

		$('.js-exit').on('click',function() {
			specific_spotlightvideo.end(0,1);
		});

	};

	this.play = function() {

		_videoPlay 				= 1;
		_videoPlayDimensions 	= $(window).width();

		/*
		Loop: Remove
		*/
		$('.js-spotlightvideo').find('video').prop('muted', false);
		$('.js-spotlightvideo').find('video').prop('loop', false);
		$('.js-spotlightvideo').find('video').prop('autoplay', false);
		$('.js-spotlightvideo').find('video').prop('src', false);

		$('.js-black').hide();

		/*
		Breakout *********************
		Breakout *********************
		Breakout *********************
		Breakout *********************
		*/
		$('body').addClass('focus');

		$('.page.content--home .spotlight .video').addClass('breakout');

			$('.js-spotlightvideo').addClass('playing');

			/*
			Portrait
			*/
			if(isOrientation == 'portrait') {
				$('.js-spotlightvideo').addClass('mobile');
			}

			/*
			Exit
			*/
			$('.js-exit').animate({
				'top'		:20,
				'z-index'	:320
			},500);

			/*
			Video
			*/
			$('.page.content--home .spotlight .video').animate({
				'width'		:'100%',
				'height'	:'100%',
			},400,function() {

				/*
				Load: Video
				*/
				var video 		= document.getElementsByClassName('js-spotlight-teaser')[0];
				video.src 		= globalUrl + '/doc/static/trailer.mp4?v=2';
				video.load();

				/*
				Listeners
				*/
				video.onloadeddata = function() {
					
					video.currentTime = 0;

					/*
				    Show
				    */
					setTimeout(function() {
						$('.js-spotlightvideo').find('video').animate({
							'opacity':1
						},500,function() {

							video.play();

						});
					},200);

					/*
					Hide things
					*/
					$('.js-spotlightvideo').find('.float').hide();

				};

				/*
				Ended?
				*/
				video.onended = function () {
					specific_spotlightvideo.end(1,1);
				};

			});

	};

	this.end = function(
		scroll,
		animation
	) {

		_videoPlay 				= 0;
		_videoPlayDimensions 	= 0;

		$('.js-spotlightvideo').removeClass('mobile playing');

		/*
		Loop: Remove
		*/
		$('.js-spotlightvideo').find('video').prop('muted', true);
		$('.js-spotlightvideo').find('video').prop('loop', true);
		$('.js-spotlightvideo').find('video').prop('autoplay', true);
		$('.js-spotlightvideo').find('video').prop('src', false);

		$('.js-black').show();

		/*
		Breakout
		*/
		$('body').removeClass('focus');

		$('.page.content--home .spotlight .video').removeClass('breakout');

		/*
		Exit
		*/
		$('.js-exit').animate({
			'top'		:-110,
			'z-index'	:0
		},500);

		/*
		End with animation ******
		End with animation ******
		End with animation ******
		End with animation ******
		*/
		if(animation == 1) {

			$('.page.content--home .spotlight .video').animate({

				'width'		: $('.js-spotlight-width').val(),
				'height'	: $('.js-spotlight-height').val()

			},400,function() {

				$('.page.content--home .spotlight .video').css('height', '');
				$('.page.content--home .spotlight .video').css('width', '');

				/*
				Load: Video
				*/
				var video 		= document.getElementsByClassName('js-spotlight-teaser')[0];
				video.src 		= globalUrl + '/doc/static/loop.mp4?v=2';
				video.load();

				/*
				Listeners
				*/
				video.onloadeddata = function() {
					
					video.currentTime = 0; 
					video.play();

					_loopPlay			= 1;

					/*
				    Show
				    */
					//setTimeout(function() {
						$('.js-spotlightvideo').find('video').animate({
							'opacity':1
						},0);
					//},200);

					/*
					Show things
					*/
					$('.js-spotlightvideo').find('.float').show();

				};

			});

		} else {

			/*
			End without animation ******
			End without animation ******
			End without animation ******
			End without animation ******
			*/
			$('.page.content--home .spotlight .video').css('height', '');
			$('.page.content--home .spotlight .video').css('width', '');

			/*
			Load: Video
			*/
			var video 		= document.getElementsByClassName('js-spotlight-teaser')[0];
			video.src 		= globalUrl + '/doc/static/loop.mp4?v=2';
			video.load();

			/*
			Listeners
			*/
			video.onloadeddata = function() {
				
				video.currentTime = 0; 
				video.play();

				/*
			    Show
			    */
				//setTimeout(function() {
					$('.js-spotlightvideo').find('video').animate({
						'opacity':1
					},0);
				//},200);

				/*
				Show things
				*/
				$('.js-spotlightvideo').find('.float').show();

			};

		}

		/*
		Scroll down
		*/
		if(scroll != 0 ) {
			$('html,body').animate({
		        scrollTop: $(".slideshows").offset().top - 70
		    },1000);
	    }

	};
		
}).apply(specific_spotlightvideo); 