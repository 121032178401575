/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_firstload = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/*
		Trigger: modal and popup preloader
		*/
		images.preload();
		modal.onready();
		popup.onready();
		form.onready();
		if(pollConnection === 1) { poll_connection.onready(); }
		
		/*
		Trigger: onready classes
		*/
		specific_mobilemenu.onready();

		/*
		Header: Logo
		*/
		$(document).on('click','.js-logo',function() {
			$('html,body').stop().animate({
		        scrollTop: 0,
		    },1000);
		});

		$(document).on('click','.js-down-icon',function() {
			$('html,body').stop().animate({
		        scrollTop: $(".slideshows").offset().top - 70
		    },500);
		});

		$(document).on('click','.js-info',function() {
			if(
				$(this).hasClass('active')
			) {
				$(this).removeClass('active');
			} else {
				$(this).addClass('active');
			}
		});

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_interactions_firstload); 