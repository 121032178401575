/*
Version 0.115

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2021
	20-01-2021
	- [BORN] This class is born. Hooray!

!Usage
	specific_mobilemenu.onready();

	
!Wishlist

	none
	
*/
  
var specific_categories = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	this.onready = function() {

	};
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************x
	// METHODS ******************************************************************
	
	this.showandhide = function(
		_cat
	) {

		$('.js-nav-item').removeClass('active');

		switch(_cat) {

			default:
				$('.js-slideshow').show();
				$('.js-nav-item-alles').addClass('active');
			break;

			case 'illustratie':
				$('.js-slideshow').hide();
				$('.js-cat-illustration').show();
				$('.js-nav-item-illustratie').addClass('active');

			break;

			case 'ontwerp':
				$('.js-slideshow').hide();
				$('.js-cat-design').show();
				$('.js-nav-item-ontwerp').addClass('active');
			break;

			case 'animatie':
				$('.js-slideshow').hide();
				$('.js-cat-animation').show();
				$('.js-nav-item-animatie').addClass('active');
			break;

		}

	};

}).apply(specific_categories); 