/*
Version 0.115

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	01-03-2018
	- [ADDED] Added document.ready to onready.
	26-02-2018
	- [ADDED] Open en Close methods are extended with new function to prevent body scrolling when menu is open
2017
	30-11-2017
	- [CHANGE] .js-menu-button is now changed to .js-mobile-menu-button
	15-10-2017
	- [METHOD] Add and remove .active class to the js-menu-button.
	30-08-2017
	- [BORN] This class is born. Hooray!

!Usage
	specific_mobilemenu.onready();

	
!Wishlist

	none
	
*/
  
var specific_slideshows_smartphone = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	// none
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	/*
	Set: Resolution
	*/
	this.setResolution = function(
		resolution
	) {

		/*
		Bigger than 1920
		*/
		if(resolution == '1920+') {
			_aspectRatio	= 1920;
			_newHeight 		= 842;

			$('.js-slideshow').height(_newHeight);

			$('.js-portrait-slideshows-loading').html('').hide();
			$('.js-landscape-slideshows-loading').show();
		} 

		/*
		Bigger than 1920 and lower
		*/
		if(resolution == '1920-') {
			_aspectRatio	= 1920 / 1080;
			_newHeight 		= Math.round($(window).width() / _aspectRatio) / 100 * 78;

			$('.js-slideshow').height(_newHeight);

			$('.js-portrait-slideshows-loading').html('').hide();
			$('.js-landscape-slideshows-loading').show();
		}

		/*
		Portrait
		*/
		if(resolution == 'portrait') {

			_aspectRatio	= 1080 / 1920;
			_newHeight 		= Math.round($(window).width() / _aspectRatio);

			$('.js-slideshow').height(_newHeight);

			$('.js-landscape-slideshows-loading').html('').hide();
			$('.js-portrait-slideshows-loading').show();
		}

	};

	/*
	Swiper
	Swiper
	Swiper
	Swiper
	*/
	this.initSwiper = function() {

		/*
		Slideshows
		*/
		$('.swiper-container').each(function(index, element) {

			var $this = $(this);
			$this.addClass("instance-" + index);

			$this.parent().find('.js-slideshow-left').addClass("btn-prev-" + index);
    		$this.parent().find('.js-slideshow-right').addClass("btn-next-" + index);

			var swiper = new Swiper('.instance-' + index, {

			navigation: {
			    nextEl: '.btn-next-' + index,
			    prevEl: '.btn-prev-' + index
			},

			on: {
			      
			      init: function () {

			      	/*
			      	Check on screen
			      	*/
			      	specific_slideshows_smartphone.checkVideoInScreen();

			      },

			      slideChange: function() {

			      	/*
			      	Check on screen
			      	*/
			      	specific_slideshows_smartphone.checkVideoInScreen();

			      	/*
			      	Set: Navigation
			      	*/
					if(this.activeIndex == (this.slides.length-1)) {

						this.$el.parent().find('.js-slideshow-left').addClass('active');
						this.$el.parent().find('.js-slideshow-right').removeClass('active');

					} else if(this.activeIndex == 0) {

						this.$el.parent().find('.js-slideshow-right').addClass('active');
						this.$el.parent().find('.js-slideshow-left').removeClass('active');

					} else {

						this.$el.parent().find('.js-slideshow-left').addClass('active');
						this.$el.parent().find('.js-slideshow-right').addClass('active');

					}

			      }
			      
			    }

			});

		});

	};

	/*
	Check video is on screen
	*/
	this.checkVideoInScreen = function() {

		$('.js-smartphone-videoplayer').each(function() {
			    
		    var activePlayer 	= $(this).data('id');
			var _url 			= $(this).data('url');

			if(
				$('.js-videoplayer-smartphone-' + activePlayer).isOnScreen() == true
			) {
				
			    if( 
			    	$('.js-videoplayer-smartphone-' + activePlayer).hasClass('active') == false
			    ) {

				    setTimeout(function() {

						$('.js-videoplayer-smartphone-' + activePlayer).addClass('active');

						var video = $('.js-videoplayer-smartphone-' + activePlayer);

						video[0].src = _url;
						video[0].load();

						video[0].play();

					},200);

			 	}

			} else {

				if( 
			    	$('.js-videoplayer-smartphone-' + activePlayer).hasClass('active') == true
			    ) {

			      $('.js-videoplayer-smartphone-' + activePlayer).removeClass('active');
			      $('.js-videoplayer-smartphone-' + activePlayer).prop('src','');

			  }

			}
		    
		});
		
	};
		
}).apply(specific_slideshows_smartphone); 