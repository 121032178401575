/*
Version 0.115

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2021
	20-01-2021
	- [BORN] This class is born. Hooray!

!Usage
	specific_mobilemenu.onready();

	
!Wishlist

	none
	
*/
  
var specific_scroller = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	// none
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************x
	// METHODS ******************************************************************
	
	this.onready = function() {

		switch(url[1]) {

			default:
			break;
			
			case 'over-mij':
				
				_class = '.about';

				$('html,body').stop().animate({
			        scrollTop: $(_class).offset().top - 70
			    },1000);

			break;

			case 'portfolio':
				
				_class = '.slideshows';

				$('html,body').stop().animate({
			        scrollTop: $(_class).offset().top - 70
			    },1000);

			break;

			case 'contact':
				
				_class = '.contact';

				$('html,body').stop().animate({
			        scrollTop: $(_class).offset().top - 70
			    },1000);

			break;

		}


	};

}).apply(specific_scroller); 