/*
Version 0.110

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	08-03-2019
	- [CHANGE] Added variable check to set height and with when not given in on windowResize();
	11-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_window = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	// none
	
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
		/**
		Window: resize
		**/
		this.windowResize = function(scrolled,h,w) {
			
			/*
			Set: Variables
			*/
			if(typeof h === 'undefined') {
				h = $(window).height();
			}
			if(typeof w === 'undefined') {
				w = $(window).width();
			}

			/*
			Aspect ratio slideshows
			*/
			//if(isOrientation != _activeOrientation) {

				_activeOrientation = isOrientation;

				switch(isOrientation) {

					default:
					break;
					
					case 'landscape':

						/*
						Unset
						*/
						_loadedPortrait = 0;

						if (w >= 1920) {

							if(_loadedLandscape == 0) {

								specific_slideshows.onready('landscape','1920+');

							} else {

								if(_device == 'smartphone') {
									specific_slideshows_smartphone.setResolution('1920+');
								} else {
									specific_slideshows.setResolution('1920+');
								}

							}

						} else {

							if(_loadedLandscape == 0) {

								specific_slideshows.onready('landscape','1920-');

							} else {

								if(_device == 'smartphone') {
									specific_slideshows_smartphone.setResolution('1920-');
								} else {
									specific_slideshows.setResolution('1920-');
								}

							}

						}

					break;

					case 'portrait':

						/*
						Unset
						*/
						_loadedLandscape = 0;
					
						if(_loadedPortrait == 0) {
							
							specific_slideshows.onready('portrait','portrait');

						} else {
							
							if(_device == 'smartphone') {
								specific_slideshows_smartphone.setResolution('portrait');
							} else {
								specific_slideshows.setResolution('portrait');
							}

						}

					break;
				}

			//}

			/*
			Spotlight: Set pixelbased ratio
			*/
			if(w > 700) {

				$('.js-spotlight-width').val(
					$('.js-spotlightvideo').width() + 'px'
				);

				$('.js-spotlight-height').val(
					$('.js-spotlightvideo').height() + 'px'
				);

			} else {

				$('.js-spotlight-width').val(
					$(window).width() + 'px'
				);

				_marge = ($(window).height() / 100) * 20;

				$('.js-spotlight-height').val(
					$(window).height() - _marge + 'px'
				);

			}

			if(
				_videoPlayDimensions != 0 &&
				w > _videoPlayDimensions &&
				w > 700
			) {

				$('.js-spotlightvideo').removeClass('mobile');
				_videoPlayDimensions 	= $(window).width();

			} else {

				if(
					_videoPlay == 1 && 
					w < 700
				) {
					$('.js-spotlightvideo').addClass('mobile');
				}

				_videoPlayDimensions 	= $(window).width();

			}
			
			document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
			
		};
		
		/**
		Window: scroll
		**/
		this.windowScroll = function(scrolled,h,w) {
	
			/*
			Scrolled navigation
			*/
			if(scrolled >= 200) {
				
				if(_fixed_shown == 0) {
				
					$('header').stop().animate({
						'top':0
					},200);

					_fixed_shown = 1;

				}


			} else {

				if(_fixed_shown == 1) {

					$('header').stop().animate({
						'top':-200
					},200);

					_fixed_shown = 0;

				}

			}

			/*
	      	Slideshow: Start active video
	      	*/
	      	if(_device == 'smartphone') {

	      		/*
		      	Check on screen
		      	*/
		      	specific_slideshows_smartphone.checkVideoInScreen();

	      	} else {

		 	   $('.swiper-slide-active').find('video').trigger('play');

			}

			/*
			Loop
			*/
			if(
				$('.js-spotlight-teaser').isOnScreen() == true
			) {

				if(_loopPlay == 0) {
					var _url	= globalUrl + '/doc/static/loop.mp4?v=2';
					$('.js-spotlight-teaser').prop('src',_url);	

					_loopPlay = 1;	
				}	

			} else {

				if(_loopPlay == 1) {

					$('.js-spotlight-teaser').prop('src','');
					_loopPlay = 0;	

				}
				
			}


		};
		
		
		/**
		Window: orientation
		**/
		this.windowOrientationChange = function() {


		};
		
}).apply(specific_interactions_window); 